import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BsFillHouseFill } from "react-icons/bs";

import {
	Button,
	Flex,
	Heading,
	Input,
	Stack,
	Text,
	useColorModeValue,
	SimpleGrid,
	useToast,
	FormControl,
	FormLabel,
	FormHelperText,
	FormErrorMessage,
	Textarea,
	Select,
	Grid,
	Icon,
	VisuallyHidden,
	GridItem,
	Image,
} from "@chakra-ui/react";
import { SOCIAL_OBJ_BASE } from "./compose/social";
import {
	SOCIAL_REGISTER_POST,
	TEMA_SEARCH_GET,
	ENDERECO_SEARCH_POST,
	IMAGE_UPLOAD_POST,
} from "../../api";
import { AlertSuccess } from "../../components/Alert";

export default function AdicionarSocial() {
	const [temas, setTemas] = useState([]);
	const [enderecos, setEnderecos] = useState([]);
	const [feedTemaLoading, setFeedTemaLoading] = useState(true);
	const [feedEnderecoLoading, setEnderecoLoading] = useState(true);
	const [social, setSocial] = useState(SOCIAL_OBJ_BASE);
	const [loading, setLoading] = useState(false);
	const [imgBanner, setImgBanner] = useState(null);
	const inputBanner = useRef();
	const toast = useToast();
	const navigate = useNavigate();

	useEffect(() => {
		loadTemas();
		loadEnderecos();
	}, []);

	/**
	 * Exibe a imagem que foi carregada no input file
	 */
	const showImageInWindow = async ({ target }) => {
		const file = target.files[0];
		const reader = new FileReader();
		const url = reader.readAsDataURL(file);
		reader.onloadend = function (e) {
			setImgBanner(reader.result);
			// uploadImageBanner(file);
		};
	};

	/**
	 * Upload image on the server
	 */
	const uploadImageBanner = async (file) => {
		const formData = new FormData();
		formData.append("image", file, file.name);
		const token = window.localStorage.getItem("token");
		const { url, options } = IMAGE_UPLOAD_POST(token, formData);
		const response = await fetch(url, options);
		const content = await response.json();
		console.log(content);
	};

	/**
	 * Carrega os temas que será utilizados para serem salvos
	 */
	const loadTemas = async () => {
		try {
			setFeedTemaLoading(true);
			const token = window.localStorage.getItem("token");
			const { url, options } = TEMA_SEARCH_GET(token);
			const response = await fetch(url, options);
			const { content } = await response.json();
			setTemas(content);
		} catch (e) {
		} finally {
			setFeedTemaLoading(false);
		}
	};

	/**
	 * Carrega a lista de endereços do usuário logado
	 */
	const loadEnderecos = async () => {
		try {
			setEnderecoLoading(true);
			const token = window.localStorage.getItem("token");
			const { url, options } = ENDERECO_SEARCH_POST(token);
			const response = await fetch(url, options);
			const { content } = await response.json();
			setEnderecos(content);
		} catch (e) {
		} finally {
			setEnderecoLoading(false);
		}
	};

	/**
	 * Preenche o hood social com os valores dos campos do formulário
	 */
	const handleVariable = ({ target }) => {
		setSocial({ ...social, [target.name]: { value: target.value } });
	};

	/**
	 * Verifica se existe algum atributo da social vazio
	 */
	const checkAllVariables = () => {
		let thereIsEmptyField = false;
		let aux_clone = structuredClone(social);
		for (let attribute in social) {
			if (social[attribute].value != "") continue;
			thereIsEmptyField = true;
			aux_clone[attribute].isInvalid = true;
		}
		setSocial(aux_clone);
		return thereIsEmptyField;
	};

	/**
	 * Realiza a chamada de inserção no servidor
	 */
	const cadastrarSocial = async () => {
		let toastAttr = {};
		try {
			setLoading(true);
			if (checkAllVariables()) {
				let attrToast = {};
				attrToast.title = `Preencha todos os campos`;
				attrToast.position = "top";
				attrToast.status = "error";
				attrToast.isClosable = true;
				toast(attrToast);
				return;
			}

			// Converte o Objet JSON em FormData
			const formData = new FormData();
			const file = inputBanner.current.files[0];
			formData.append("imageBanner", file, file.name);
			for (var key in social) {
				formData.append(key, social[key].value);
			}

			// Realiza a chamada da API
			const token = window.localStorage.getItem("token");
			const { url, options } = SOCIAL_REGISTER_POST(token, formData);
			const response = await fetch(url, options);
			const objResponse = await response.json();

			// Prepara a mensagem de alerta de acordo com o retorno
			if (!objResponse.idSocial) throw "erro";
			toastAttr.title = `Social adicionada com sucesso`;
			toastAttr.position = "top";
			toastAttr.status = "success";
			toastAttr.isClosable = true;
			toast(toastAttr);
			navigate("/social/" + objResponse.idSocial);
		} catch (e) {
			toastAttr.title = `Problema ao adicionar social, tente mais tarde`;
			toastAttr.position = "top";
			toastAttr.status = "error";
			toastAttr.isClosable = true;
			toast(toastAttr);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Flex
			minH={"100vh"}
			align={"center"}
			justify={"center"}
			bg={useColorModeValue("gray.50", "gray.800")}>
			<Stack
				spacing={4}
				w={"78%"}
				maxW={"100vw"}
				bg={useColorModeValue("white", "gray.700")}
				rounded={"xl"}
				boxShadow={"lg"}
				p={6}
				my={12}>
				<Heading
					lineHeight={1.1}
					fontSize={{ base: "2xl", md: "3xl" }}>
					Adicionar Social
				</Heading>
				<Text
					fontSize={{ base: "sm", sm: "md" }}
					color={useColorModeValue("gray.800", "gray.400")}></Text>

				<Grid
					templateColumns="repeat(3, 1fr)"
					gap={6}>
					<GridItem colSpan={3}>
						<FormControl>
							<FormLabel
								fontSize="sm"
								fontWeight="md"
								color="gray.700"
								_dark={{
									color: "gray.50",
								}}>
								Banner do Rolê
							</FormLabel>
							{imgBanner && (
								<Image
									rounded={"md"}
									alt={"product image"}
									src={imgBanner}
									fit={"cover"}
									align={"center"}
									w={"100%"}
									h={{
										base: "100%",
										sm: "400px",
										lg: "500px",
									}}
								/>
							)}
							<Flex
								mt={1}
								justify="center"
								px={6}
								pt={5}
								pb={6}
								borderWidth={2}
								_dark={{
									color: "gray.500",
								}}
								borderStyle="dashed"
								rounded="md">
								<Stack
									spacing={1}
									textAlign="center">
									<Icon
										mx="auto"
										boxSize={12}
										color="gray.400"
										_dark={{
											color: "gray.500",
										}}
										stroke="currentColor"
										fill="none"
										viewBox="0 0 48 48"
										aria-hidden="true">
										<path
											d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
											strokeWidth="2"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</Icon>
									<Flex
										fontSize="sm"
										color="gray.600"
										_dark={{
											color: "gray.400",
										}}
										alignItems="baseline">
										<FormLabel
											htmlFor="file-upload"
											cursor="pointer"
											rounded="md"
											fontSize="md"
											color="brand.600"
											_dark={{
												color: "brand.200",
											}}
											pos="relative"
											_hover={{
												color: "brand.400",
												_dark: {
													color: "brand.300",
												},
											}}>
											<span>Upload a file</span>
											<VisuallyHidden>
												<input
													id="file-upload"
													name="file-upload"
													type="file"
													ref={inputBanner}
													onChange={(e) => showImageInWindow(e)}
												/>
											</VisuallyHidden>
										</FormLabel>
										<Text pl={1}>or drag and drop</Text>
									</Flex>
									<Text
										fontSize="xs"
										color="gray.500"
										_dark={{
											color: "gray.50",
										}}>
										PNG, JPG, GIF up to 10MB
									</Text>
								</Stack>
							</Flex>
						</FormControl>
					</GridItem>
					<GridItem>
						<FormControl isInvalid={social.titulo.isInvalid}>
							<FormLabel>Título</FormLabel>
							<Input
								placeholder="Ex:. Filhos da PUC"
								name="titulo"
								onChange={handleVariable}
								_placeholder={{ color: "gray.500" }}
								type="text"
							/>
							<FormErrorMessage>
								É essencial informar preencher esse campo.
							</FormErrorMessage>
						</FormControl>
					</GridItem>
					<GridItem>
						<FormControl isInvalid={social.tema.isInvalid}>
							<FormLabel>Tema</FormLabel>
							<Select
								placeholder="-- SELECIONE --"
								name="tema"
								onChange={handleVariable}>
								{temas.map((tema, key) => {
									return (
										<option
											key={key}
											value={tema.idTema}>
											{" "}
											{tema.nome}{" "}
										</option>
									);
								})}
							</Select>

							<FormErrorMessage>
								É essencial informar preencher esse campo.
							</FormErrorMessage>
						</FormControl>
					</GridItem>
					<GridItem>
						<FormControl isInvalid={social.limiteConvidados.isInvalid}>
							<FormLabel>Limite de convidados</FormLabel>
							<Input
								placeholder="Limite de convidados"
								name="limiteConvidados"
								onChange={handleVariable}
								_placeholder={{ color: "gray.500" }}
								type="number"
								min={0}
								max={200}
							/>
							<FormErrorMessage>
								É essencial informar preencher esse campo.
							</FormErrorMessage>
						</FormControl>
					</GridItem>
					<FormControl isInvalid={social.endereco.isInvalid}>
						<FormLabel>Endereço</FormLabel>
						<Select
							placeholder="-- SELECIONE --"
							name="endereco"
							onChange={handleVariable}>
							{enderecos.map((endereco, key) => {
								return (
									<option
										key={key}
										value={endereco.idEndereco}>
										{" "}
										{endereco.titulo}{" "}
									</option>
								);
							})}
						</Select>

						<FormErrorMessage>
							É essencial informar preencher esse campo.
						</FormErrorMessage>
					</FormControl>
					<GridItem
						colSpan={2}
						rowSpan={3}>
						<FormControl isInvalid={social.descricao.isInvalid}>
							<FormLabel>Descrição</FormLabel>
							<Textarea
								placeholder="Descrição"
								name="descricao"
								onChange={handleVariable}
								_placeholder={{ color: "gray.500" }}
								type="text"
							/>
							<FormErrorMessage>
								É essencial informar preencher esse campo.
							</FormErrorMessage>
						</FormControl>
					</GridItem>
					<FormControl isInvalid={social.contribuicao.isInvalid}>
						<FormLabel>Método de contribuição</FormLabel>
						<Input
							placeholder="Ex: Bebida/Comida ou Dinheiro"
							name="contribuicao"
							onChange={handleVariable}
							_placeholder={{ color: "gray.500" }}
							type="text"
						/>
					</FormControl>
					<FormControl isInvalid={social.dataInicio.isInvalid}>
						<FormLabel>Data e hora Início</FormLabel>
						<Input
							placeholder="Data Inicio"
							name="dataInicio"
							onChange={handleVariable}
							_placeholder={{ color: "gray.500" }}
							type="datetime-local"
						/>
						<FormErrorMessage>
							É essencial informar preencher esse campo.
						</FormErrorMessage>
					</FormControl>
				</Grid>

				<Stack spacing={6}>
					<Button
						bg={"blue.400"}
						color={"white"}
						_hover={{
							bg: "blue.500",
						}}
						onClick={cadastrarSocial}>
						Cadastrar Social
					</Button>
				</Stack>
			</Stack>
		</Flex>
	);
}
