import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { DETAILS_USER_GET, TOKEN_POST, TOKEN_VALIDATE_POST } from "./api";

export const UserContext = React.createContext();

export const UserStorage = ({ children }) => {
	const [data, setData] = useState(null);
	const [login, setLogin] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	const navigate = useNavigate();

	useEffect(() => {
		const autoLogin = async () => {
			try {
				setLoading(true);
				const token = window.localStorage.getItem("token");
				if (token) {
					const { url, options } = TOKEN_VALIDATE_POST(token);
					const response = await fetch(url, options);
					const isValid = await response.json();
					if (isValid) await getUser(token);
				} else {
					setLogin(false);
				}
			} catch (e) {
				userLogout();
			} finally {
				setLoading(false);
			}
		};
		autoLogin();
	}, []);

	const getUser = async (token) => {
		try {
			setLoading(true);
			const { url, options } = DETAILS_USER_GET(token);
			const response = await fetch(url, options);
			const usuario = await response.json();
			setData(usuario);
			setLogin(true);
			setError(null);
		} catch (e) {
			userLogout();
			setError(e);
		} finally {
			setLoading(false);
		}
	};

	const userLogin = async (username, password) => {
		try {
			setLoading(true);
			const { url, options } = TOKEN_POST(username, password);
			const response = await fetch(url, options);
			const result = await response.json();

			if (!response.ok) throw result.message;

			window.localStorage.setItem("token", result.token);
			await getUser(result.token);
			navigate("/");
		} catch (e) {
			userLogout();
			if (e && e.message == "Failed to fetch")
				setError("Falha ao entrar, por favor tente mais tarde");
			else setError(e);
		} finally {
			setLoading(false);
		}
	};

	const userLogout = () => {
		setData(null);
		setLogin(false);
		setError(false);
		window.localStorage.removeItem("token");
	};

	return (
		<UserContext.Provider
			value={{ userLogin, userLogout, data, login, loading, error }}>
			{children}
		</UserContext.Provider>
	);
};
