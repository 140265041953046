import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
	Button,
	Flex,
	Heading,
	Input,
	Stack,
	Text,
	useColorModeValue,
	SimpleGrid,
	useToast,
	FormControl,
	FormLabel,
	FormErrorMessage,
} from "@chakra-ui/react";
import { TEMA_OBJ_BASE } from "./compose/tema";
import { TEMA_REGISTER_POST } from "../../api";

export default function AdicionarTema() {
	const [tema, setTema] = useState(TEMA_OBJ_BASE);
	const [loading, setLoading] = useState(false);
	const toast = useToast();
	const navigate = useNavigate();

	const handleVariable = ({ target }) => {
		setTema({ ...tema, [target.name]: { value: target.value } });
	};

	const checkAllVariables = () => {
		const temaProperties = Object.getOwnPropertyNames(tema);
		let existeCampoVazio = false;

		let aux_clone = structuredClone(tema);
		temaProperties.map((propertie) => {
			if (aux_clone[propertie].value == "") {
				existeCampoVazio = true;
				aux_clone[propertie].isInvalid = true;
			}
		});

		if (existeCampoVazio) {
			toast({
				title: `Preencha todos os campos`,
				position: "top",
				status: "error",
				isClosable: true,
			});
			setTema(aux_clone);
		}

		return existeCampoVazio;
	};

	const cadastrarTema = async () => {
		setLoading(true);
		if (checkAllVariables()) return;

		const token = window.localStorage.getItem("token");
		const { url, options } = TEMA_REGISTER_POST(token, tema);
		const response = await fetch(url, options);
		const result = await response.json();

		toast({
			title: `${result.message}`,
			position: "top",
			status: result.ok ? "success" : "error",
			isClosable: true,
		});

		if (result.ok) navigate("/");
		setLoading(false);
	};

	return (
		<>
			<Flex
				minH={"100vh"}
				align={"center"}
				justify={"center"}
				bg={useColorModeValue("gray.50", "gray.800")}>
				<Stack
					spacing={4}
					w={"78%"}
					maxW={"100vw"}
					bg={useColorModeValue("white", "gray.700")}
					rounded={"xl"}
					boxShadow={"lg"}
					p={6}
					my={12}>
					<Heading
						lineHeight={1.1}
						fontSize={{ base: "2xl", md: "3xl" }}>
						Adicionar Tema
					</Heading>
					<Text
						fontSize={{ base: "sm", sm: "md" }}
						color={useColorModeValue("gray.800", "gray.400")}></Text>

					<SimpleGrid
						columns={1}
						spacing={5}>
						<FormControl isInvalid={tema.nome.isInvalid}>
							<FormLabel>Tema</FormLabel>
							<Input
								placeholder="Nome do tema"
								name="nome"
								onChange={handleVariable}
								_placeholder={{ color: "gray.500" }}
								type="text"
							/>
							<FormErrorMessage>
								É essencial informar preencher esse campo.
							</FormErrorMessage>
						</FormControl>
					</SimpleGrid>

					<Stack spacing={6}>
						<Button
							isLoading={loading}
							bg={"blue.400"}
							color={"white"}
							_hover={{
								bg: "blue.500",
							}}
							onClick={cadastrarTema}>
							Cadastrar Tema
						</Button>
					</Stack>
				</Stack>
			</Flex>
			<Text>Listagem de temas cadastrados</Text>
		</>
	);
}
