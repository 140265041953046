import { Button, Spinner, useColorModeValue, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { PARTICIPANTE_POST, STATUS_SOCIAL_GET } from "../../../api";

export function ButtonSolicitacao() {
	const [loading, setLoading] = useState(false);
	const [nivelUsuario, setNivelUsuario] = useState();
	const [status, setStatus] = useState();
	const toast = useToast();

	useEffect(() => {
		loadStatusParticipante();
	}, []);

	const loadStatusParticipante = async () => {
		const token = window.localStorage.getItem("token");
		const id_social = window.location.href.split("/").pop();
		const { url, options } = STATUS_SOCIAL_GET(token, id_social);
		const response = await fetch(url, options);
		const { descricao, status } = await response.json();
		setNivelUsuario(descricao);
		setStatus(status);
	};

	const solicitarParticipacao = async () => {
		try {
			setLoading(true);
			const token = window.localStorage.getItem("token");
			const id_social = window.location.href.split("/").pop();
			const { url, options } = PARTICIPANTE_POST(token, id_social);
			const response = await fetch(url, options);
			const obj_return = await response.json();
			if (obj_return) {
				toast({
					title: `${obj_return.message}`,
					position: "top",
					status: "success",
					isClosable: true,
				});
			}
		} catch (e) {
			toast({
				title: `Problema ao enviar requisição, por favor tente mais tarde!`,
				position: "top",
				status: "error",
				isClosable: true,
			});
		} finally {
			setLoading(false);
		}
	};

	if (nivelUsuario == "Organizador") return <></>;

	if (status == "PENDENTE")
		return <Button>Solicitação enviada ao organizador!</Button>;

	if (status == "APROVADO") return <Button>Solicitação APROVADA!</Button>;

	return (
		<Button
			rounded={"none"}
			w={"full"}
			mt={8}
			size={"lg"}
			py={"7"}
			bg={useColorModeValue("gray.900", "gray.50")}
			color={useColorModeValue("white", "gray.900")}
			textTransform={"uppercase"}
			_hover={{
				transform: "translateY(2px)",
				boxShadow: "lg",
			}}
			onClick={solicitarParticipacao}
			disabled={loading}>
			Quero participar!
		</Button>
	);
}
