import {
	Box,
	Flex,
	Avatar,
	Button,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	MenuDivider,
	useDisclosure,
	useColorModeValue,
	Stack,
	useColorMode,
	Center,
	HStack,
	IconButton,
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverArrow,
	PopoverCloseButton,
	PopoverHeader,
	PopoverBody,
	ButtonGroup,
	FormLabel,
	Text,
	Badge,
	Link as LinkChakra,
} from "@chakra-ui/react";
import {
	MoonIcon,
	SunIcon,
	CloseIcon,
	HamburgerIcon,
	BellIcon,
} from "@chakra-ui/icons";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../UserContext";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import { Links } from "./compose/links";
import { LIST_PARTICIPANTES_GET, PARTICIPANTE_SOCIAL_PUT } from "../../api";

const Header = () => {
	const { data, login, userLogout } = useContext(UserContext);
	const { colorMode, toggleColorMode } = useColorMode();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [listToApproval, setListToApproval] = useState([]);

	const navigate = useNavigate();

	const NavLink = ({ children }) => {
		return (
			<LinkChakra
				px={2}
				py={1}
				rounded={"md"}>
				{children.label}
			</LinkChakra>
		);
	};

	return (
		<>
			<Box
				bg={useColorModeValue("gray.100", "gray.900")}
				px={4}>
				<Flex
					h={16}
					alignItems={"center"}
					justifyContent={"space-between"}>
					<IconButton
						size={"md"}
						icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
						aria-label={"Open Menu"}
						display={{ md: "none" }}
						onClick={isOpen ? onClose : onOpen}
					/>

					<HStack
						spacing={8}
						alignItems={"center"}>
						<Box>
							<Link to="/">Rolê Aleatório</Link>
						</Box>
						<HStack
							as={"nav"}
							spacing={4}
							display={{ base: "none", md: "flex" }}></HStack>
					</HStack>
					<Flex alignItems={"center"}>
						{login ? (
							<Stack
								direction={"row"}
								spacing={7}>
								<Link to="/adicionar-social">
									<Button
										fontSize={"sm"}
										background={"transparent"}>
										Adicionar Social
									</Button>
								</Link>
								<Button onClick={toggleColorMode}>
									{colorMode === "light" ? <MoonIcon /> : <SunIcon />}
								</Button>
								<Popover>
									<PopoverTrigger>
										<IconButton
											size="sm"
											icon={<BellIcon />}
											padding={"20px"}
										/>
									</PopoverTrigger>
									<PopoverContent>
										<PopoverArrow />
										<PopoverBody>
											{listToApproval.map((participante, key) => {
												return (
													<Flex key={key}>
														<Box ml="3">
															<Text fontWeight="bold">
																PUCPR Eletrofunk
																<Badge
																	ml="1"
																	colorScheme="green">
																	Bora
																</Badge>
															</Text>
															<Text fontSize="sm">
																Você foi aprovado na PUCR Eletrofunk
															</Text>
														</Box>
														<PopoverArrow />
													</Flex>
												);
											})}
										</PopoverBody>
									</PopoverContent>
								</Popover>
								<Menu>
									<MenuButton
										as={Button}
										rounded={"full"}
										variant={"link"}
										cursor={"pointer"}
										minW={0}>
										<Avatar
											size={"sm"}
											name={data && data.nome}
										/>
									</MenuButton>
									<MenuList alignItems={"center"}>
										<br />
										<Center>
											<Avatar
												size={"2xl"}
												name={data && data.nome}
											/>
										</Center>
										<br />
										<Center>
											<p>{data && data.nome}</p>
										</Center>
										<br />
										<MenuDivider />
										<MenuItem>Perfil</MenuItem>
										<MenuItem onClick={() => navigate("/adicionar-tema")}>
											Cadastrar Tema
										</MenuItem>
										<MenuItem onClick={() => navigate("/adicionar-endereco")}>
											Cadastrar Endereço
										</MenuItem>
										<MenuDivider />
										<MenuItem onClick={userLogout}>Sair</MenuItem>
									</MenuList>
								</Menu>
							</Stack>
						) : (
							<Stack
								flex={{ base: 1, md: 0 }}
								justify={"flex-end"}
								direction={"row"}
								spacing={6}>
								<Link to={"/cadastro"}>
									<Button
										fontSize={"sm"}
										fontWeight={400}
										verticalAlign={"sub"}>
										Cadastre-se
									</Button>
								</Link>
								<Button onClick={toggleColorMode}>
									{colorMode === "light" ? <MoonIcon /> : <SunIcon />}
								</Button>
								<Link to={"/login"}>
									<Button
										fontSize={"sm"}
										fontWeight={600}
										color={"white"}
										bg={"pink.400"}
										_hover={{
											bg: "pink.300",
										}}>
										Login
									</Button>
								</Link>
							</Stack>
						)}
					</Flex>
				</Flex>
				{isOpen ? (
					<Box
						pb={4}
						display={{ md: "none" }}>
						<Stack
							as={"nav"}
							spacing={4}>
							{Links.map((item, key) => (
								<NavLink key={key}>{item}</NavLink>
							))}
						</Stack>
					</Box>
				) : null}
			</Box>
		</>
	);
};

export default Header;
