export const API_URL = process.env.REACT_APP_API_ROLE;

export const TOKEN_POST = (username, password) => {
	return {
		url: `${API_URL}/api/usuario/auth`,
		options: {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"User-Agent":
					"Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.124 Safari/537.36 Edg/91.0.864.59",
			},
			body: JSON.stringify({
				email: username,
				senha: password,
			}),
		},
	};
};

export const TOKEN_VALIDATE_POST = (token) => {
	return {
		url: `${API_URL}/api/token/validate`,
		options: {
			method: "POST",
			headers: {
				Authorization: `Bearer ${token}`,
			},
		},
	};
};

export const USER_REGISTER_POST = (obj_user) => {
	return {
		url: `${API_URL}/api/usuario`,
		options: {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(_prepareObjToPost(obj_user)),
		},
	};
};

export const DETAILS_USER_GET = (token) => {
	return {
		url: `${API_URL}/api/usuario/details`,
		options: {
			method: "GET",
			headers: {
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			},
		},
	};
};

export const SOCIAIS_SEARCH_GET = (token, search = "", page = 0, size = 10) => {
	return {
		url: `${API_URL}/api/social${search ? "?search=" + search : ""}`,
		options: {
			method: "GET",
			headers: {
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			},
		},
	};
};

export const DETAILS_SOCIAL_GET = (token, id_social) => {
	return {
		url: `${API_URL}/api/social/${id_social}`,
		options: {
			method: "GET",
			headers: {
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			},
		},
	};
};

export const SOCIAL_REGISTER_POST = (token, formData) => {
	return {
		url: `${API_URL}/api/social`,
		options: {
			method: "POST",
			headers: {
				Authorization: `Bearer ${token}`,
				contentType: false,
			},
			body: formData,
		},
	};
};

export const PARTICIPANTE_POST = (token, id_social) => {
	return {
		url: `${API_URL}/api/participante/${id_social}`,
		options: {
			method: "POST",
			headers: {
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			},
		},
	};
};

export const TEMA_REGISTER_POST = (token, obj_tema) => {
	return {
		url: `${API_URL}/api/tema`,
		options: {
			method: "POST",
			headers: {
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(_prepareObjToPost(obj_tema)),
		},
	};
};

export const TEMA_SEARCH_GET = (token, page = 0, size = 10) => {
	return {
		url: `${API_URL}/api/tema`,
		options: {
			method: "GET",
			headers: {
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			},
		},
	};
};

export const LIST_PARTICIPANTES_GET = (token) => {
	return {
		url: `${API_URL}/api/participante`,
		options: {
			method: "GET",
			headers: {
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			},
		},
	};
};

export const ENDERECO_REGISTER_POST = (token, obj_endereco) => {
	return {
		url: `${API_URL}/api/endereco`,
		options: {
			method: "POST",
			headers: {
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(_prepareObjToPost(obj_endereco)),
		},
	};
};

export const ENDERECO_SEARCH_POST = (token, page = 0, size = 10) => {
	return {
		url: `${API_URL}/api/endereco`,
		options: {
			method: "GET",
			headers: {
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			},
		},
	};
};

export const LIST_PARTICIPANTES_SOCIAL_GET = (token, idSocial) => {
	return {
		url: `${API_URL}/api/participante/ref/${idSocial}`,
		options: {
			method: "GET",
			headers: {
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			},
		},
	};
};

export const PARTICIPANTE_SOCIAL_PUT = (token, idParticipante, status) => {
	return {
		url: `${API_URL}/api/participante/${idParticipante}`,
		options: {
			method: "PUT",
			headers: {
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ status: status }),
		},
	};
};

export const PARTICIPANTE_AVALIACAO_SOCIAL_PUT = (
	token,
	idParticipante,
	nota
) => {
	return {
		url: `${API_URL}/api/participante/rating/${idParticipante}/${nota}`,
		options: {
			method: "PUT",
			headers: {
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			},
		},
	};
};

const _prepareObjToPost = (obj_base) => {
	let obj_aux = {};
	Object.keys(obj_base).map((atribute) => {
		obj_aux[atribute] = obj_base[atribute].value;
	});
	return obj_aux;
};

export const IMAGE_UPLOAD_POST = (token, formData) => {
	return {
		url: `${API_URL}/api/image/upload`,
		options: {
			method: "POST",
			headers: {
				Authorization: `Bearer ${token}`,
			},
			body: formData,
		},
	};
};

export const VALIDATE_USER = (token_to_validate) => {
	return {
		url: `${API_URL}/api/usuario/registrationConfirm?token=${token_to_validate}`,
		options: {
			method: "GET",
		},
	};
};

export const OPTIONS_ESTADO_GET = (token) => {
	return {
		url: `${API_URL}/api/estado`,
		options: {
			method: "GET",
			headers: {
				Authorization: `Bearer ${token}`,
			},
		},
	};
};

export const OPTIONS_CIDADE_GET = (token, estado) => {
	return {
		url: `${API_URL}/api/cidade?estado=${estado}`,
		options: {
			method: "GET",
			headers: {
				Authorization: `Bearer ${token}`,
			},
		},
	};
};

export const STATUS_SOCIAL_GET = (token, idSocial) => {
	return {
		url: `${API_URL}/api/participante/details?idSocial=${idSocial}`,
		options: {
			method: "GET",
			headers: {
				Authorization: `Bearer ${token}`,
			},
		},
	};
};
