import {
	Box,
	Button,
	FormControl,
	Heading,
	Input,
	Stack,
	Text,
} from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../../../UserContext";

export default function FormLogin() {
	const { userLogin, error } = useContext(UserContext);

	const [email, setEmail] = useState(null);
	const [senha, setSenha] = useState(null);
	const [loading, setLoading] = useState(false);

	const submitLogin = async (e) => {
		e.preventDefault();
		setLoading(true);
		userLogin(email, senha).then(() => {
			setLoading(false);
		});
	};

	return (
		<Stack
			bg={"gray.50"}
			rounded={"xl"}
			p={{ base: 4, sm: 6, md: 8 }}
			spacing={{ base: 8 }}
			maxW={{ lg: "lg" }}>
			<Stack spacing={4}>
				<Heading
					color={"gray.800"}
					lineHeight={1.1}
					fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }}>
					Entre e Bora Rolê
					<Text
						as={"span"}
						bgGradient="linear(to-r, red.400,pink.400)"
						bgClip="text">
						!
					</Text>
				</Heading>
				<Text
					color={"gray.500"}
					fontSize={{ base: "sm", sm: "md" }}>
					Um projeto para conectar a gente, aquela dose de felicidade e
					aleatoriedade que vale o rolê!
				</Text>
				<Text
					color={"red.500"}
					fontSize={{ base: "sm", sm: "md" }}>
					{error}
				</Text>
			</Stack>
			<Box
				as={"form"}
				mt={10}>
				<Stack spacing={4}>
					<FormControl
						id="email"
						isInvalid={email !== null && email === ""}>
						<Input
							type="email"
							placeholder="Email ou telefone"
							bg={"gray.100"}
							border={0}
							color={"gray.500"}
							_placeholder={{ color: "gray.500" }}
							tabIndex={1}
							onChange={(e) => setEmail(e.target.value)}
						/>
					</FormControl>
					<FormControl
						id="password"
						isInvalid={senha !== null && senha === ""}>
						<Input
							type="password"
							placeholder="Senha"
							bg={"gray.100"}
							border={0}
							color={"gray.500"}
							_placeholder={{ color: "gray.500" }}
							tabIndex={2}
							onChange={(e) => setSenha(e.target.value)}
						/>
					</FormControl>
				</Stack>
				<Stack
					direction={{ base: "column", sm: "row" }}
					align={"start"}
					justify={"space-between"}
					mt={8}>
					<Link
						to="/reseteSenha"
						tabIndex={4}>
						<Button color={"blue.400"}>Esqueceu a senha?</Button>
					</Link>
					<Link
						to="/cadastro"
						tabIndex={5}>
						<Button color={"blue.400"}>Cadastre-se</Button>
					</Link>
				</Stack>
				<Button
					type="submit"
					isLoading={loading}
					fontFamily={"heading"}
					mt={8}
					w={"full"}
					bgGradient="linear(to-r, red.400,pink.400)"
					color={"white"}
					_hover={{
						bgGradient: "linear(to-r, red.400,pink.400)",
						boxShadow: "xl",
					}}
					onClick={submitLogin}
					tabIndex={3}>
					Entrar
				</Button>
			</Box>
			form
		</Stack>
	);
}
