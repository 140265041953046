import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../UserContext";
import Login from "../../view/Login";
import { Spinner, Fade, useDisclosure } from "@chakra-ui/react";

const ProtectedRoute = ({ children }) => {
	const { login, loading } = useContext(UserContext);

	if (loading) return <Spinner />;

	return login ? <Fade in={true}>{children}</Fade> : <Login />;
};

export default ProtectedRoute;
