import 'bootstrap/dist/css/bootstrap.min.css';
import ReactDOM  from "react-dom/client";
import React from 'react';
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import App from './App';


const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <ChakraProvider>
    <App />
    </ChakraProvider>
  </React.StrictMode>
);