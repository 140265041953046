import { Spinner } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { VALIDATE_USER } from "../../api";

const ConfirmacaoUsuario = () => {
	const [responseValidUser, setResponseValidUser] = useState("");
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const validateUser = async () => {
			setLoading(true);
			const token = location.search.substr(1).split("=")[1];
			const { url, options } = VALIDATE_USER(token);
			const response = await fetch(url, options);
			const result = await response.json();

			setResponseValidUser(result.message);
			setLoading(false);
		};

		validateUser();
	}, []);

	return !loading ? <div>{responseValidUser}</div> : <Spinner />;
};

export default ConfirmacaoUsuario;
