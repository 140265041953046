import { Avatar, Divider, Skeleton, Spinner } from "@chakra-ui/react";
import React, { lazy, useContext, useEffect, useState } from "react";
import { SOCIAIS_SEARCH_GET } from "../../api.js";
import { Container, Heading, chakra, Button } from "@chakra-ui/react";
import { Box, HStack, Link, Text, Stack } from "@chakra-ui/layout";
import { Image } from "@chakra-ui/image";
import { useColorModeValue } from "@chakra-ui/color-mode";
import { Tag } from "@chakra-ui/tag";
import { useNavigate } from "react-router-dom";
import { Icon, LinkIcon } from "@chakra-ui/icons";
import { DETAILS_SOCIAL_GET } from "../../api.js";
import whatsapp from "../../assets/images/whatsapp.png";
import Search from "./compose/seach.js";

const Feed = () => {
	const [sociais, setSociais] = useState([]);
	const [feedLoading, setFeedLoading] = useState(true);
	const navigate = useNavigate();

	const site = window.location.hostname;

	useEffect(() => {
		loadSociais();
	}, []);

	const loadSociais = async () => {
		try {
			setFeedLoading(true);
			const token = window.localStorage.getItem("token");
			const { url, options } = SOCIAIS_SEARCH_GET(token);
			const response = await fetch(url, options);
			const { content } = await response.json();
			console.log(content);
			if (!Array.isArray(content)) content = [content];
			setSociais(content);
		} catch (e) {
		} finally {
			setFeedLoading(false);
		}
	};

	if (feedLoading) {
		return (
			<center>
				<Spinner />
			</center>
		);
	}

	const BlogAuthor = (props) => {
		const { name, date } = props;
		return (
			<HStack
				marginTop="2"
				spacing="2"
				display="flex"
				alignItems="center">
				<Avatar name={name} />
				<Text fontWeight="medium">{name}</Text>
				<Text>—</Text>
				<Text>{date.toLocaleDateString()}</Text>
			</HStack>
		);
	};

	const BlogTags = (props) => {
		const { tags, marginTop } = props;
		return (
			<HStack
				spacing={2}
				marginTop={marginTop}>
				{tags.map((tag, key) => {
					return (
						<Tag
							size={"md"}
							variant="solid"
							colorScheme="orange"
							key={key}>
							{tag}
						</Tag>
					);
				})}
			</HStack>
		);
	};

	const ContentSocial = ({ item }) => {
		const { idSocial, titulo, tema, imgBanner } = item;
		return (
			<>
				<Box
					p={6}
					m={3}
					borderRadius={6}
					borderWidth={1}
					borderStyle={"solid"}
					borderColor={useColorModeValue("gray.200", "gray.700")}
					justifyContent="space-between"
					flexDirection={{ base: "row", sm: "row" }}>
					<BlogAuthor
						name="Social"
						date={new Date("2021-04-06T19:01:27Z")}
					/>

					<Box
						marginTop={{ base: "1", sm: "5" }}
						display="flex"
						flexDirection={{ base: "column", sm: "row" }}
						justifyContent="space-between">
						<Box
							display="flex"
							flex="1"
							marginRight="3"
							position="relative"
							alignItems="center">
							<Image
								height={"-webkit-fill-available"}
								borderRadius="lg"
								src={imgBanner}
								alt="Banner da social"
								fit={"cover"}
								align={"50% 50%"}
								fallback={
									<Skeleton
										height={"20em"}
										width={"100%"}
										isLoaded={false}
									/>
								}
							/>
						</Box>
						<Box
							display="flex"
							flex="1"
							flexDirection="column"
							justifyContent="center"
							marginTop={{ base: "3", sm: "0" }}>
							<Text
								as="p"
								marginTop="2"
								color={useColorModeValue("blue.700", "blue.200")}
								fontSize="lg">
								{titulo}
							</Text>

							<Text
								as="p"
								marginTop="2"
								color={useColorModeValue("green.700", "green.200")}
								fontSize="lg">
								{tema}
							</Text>

							<Link
								href={`https://wa.me/?text=http://${site}/social/${idSocial}`}>
								<Text as="samp">COMPARTILHE</Text>
								<Icon
									as={Link}
									color="opacity"
									w={7}
									h={7}
								/>
								<img
									alt="Whasapp Icon"
									width={"40px"}
									align={"right"}
									src={whatsapp}
								/>
							</Link>
							<br></br>

							<Text
								colorScheme="blue"
								variant="solid"
								size={"xs"}
								height={"38px"}
								width={"130px"}>
								Inscritos : 5/10
							</Text>
							<br></br>

							<Text
								colorScheme="blue"
								variant="solid"
								size={"xs"}
								height={"36px"}
								width={"190px"}>
								Contribuição : Bebida ou carne
							</Text>
							<br></br>

							<Button
								colorScheme="teal"
								variant="outline"
								size={"sm"}
								onClick={(e) => navigate("/social/" + idSocial)}>
								Ver descrição completa
							</Button>
						</Box>
					</Box>
				</Box>
			</>
		);
	};

	return (
		<Container
			p="6"
			maxW="680px">
			<Search setSociais={setSociais} />
			{sociais.length > 0 ? (
				sociais.map((social, key) => {
					return (
						<Stack key={key}>
							<ContentSocial item={social} />
						</Stack>
					);
				})
			) : (
				<center>Nenhuma social encontrada</center>
			)}
		</Container>
	);
};

export default Feed;
