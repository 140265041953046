import { VisibleEffect } from "./compose/VisibleEffect";
import FormLogin from "./compose/FormLogin";
import React from "react";

import { Box, Container, SimpleGrid } from "@chakra-ui/react";
import BlurEffect from "./compose/BlurEffect";

export default function Login() {
	const containerProps = {
		as: SimpleGrid,
		maxW: "7xl",
		columns: { base: 1, md: 2 },
		spacing: { base: 10, lg: 32 },
		py: { base: 10, sm: 20, lg: 32 },
	};

	return (
		<Box position={"relative"}>
			<Container {...containerProps}>
				<VisibleEffect />
				<FormLogin />
			</Container>
			<BlurEffect />
		</Box>
	);
}
