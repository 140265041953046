import { Divider, Spinner } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { TEMA_SEARCH_GET } from '../../api.js';
import { Container, Heading } from '@chakra-ui/react';
import { Box, HStack, Link, Text, Stack } from '@chakra-ui/layout';
import { Image } from '@chakra-ui/image';
import { useColorModeValue } from '@chakra-ui/color-mode';
import { Tag } from '@chakra-ui/tag';
import { useNavigate } from 'react-router-dom';

const FeedTema = () => {
  const [temas, setTemas] = useState([]);
  const [feedTemaLoading, setFeedTemaLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    loadTemas();
  }, []);

  const loadTemas = async () => {
    try {
      setFeedTemaLoading(true);
      const token = window.localStorage.getItem('token');
      const { url, options } = TEMA_SEARCH_GET(token);
      const response = await fetch(url, options);
      const { content } = await response.json();
      setTemas(content);
    } catch (e) {
    } finally {
      setFeedTemaLoading(false);
    }
  };

  if (feedTemaLoading) {
    return (
      <center>
        <Spinner />
      </center>
    );
  }

  const BlogAuthor = (props) => {
    return (
      <HStack marginTop="2" spacing="2" display="flex" alignItems="center">
        <Image
          borderRadius="full"
          boxSize="40px"
          src="https://100k-faces.glitch.me/random-image"
          alt={`Avatar of ${props.name}`}
        />
        <Text fontWeight="medium">{props.name}</Text>
        <Text>—</Text>
        <Text>{props.date.toLocaleDateString()}</Text>
      </HStack>
    );
  };

  const BlogTags = (props) => {
    return (
      <HStack spacing={2} marginTop={props.marginTop}>
        {props.tags.map((tag, key) => {
          return (
            <Tag size={'md'} variant="solid" colorScheme="orange" key={key}>
              {tag}
            </Tag>
          );
        })}
      </HStack>
    );
  };

  const ContentTemas = ({ item }) => {
    const { nome, idTema } = item;
    return (
      <Box
        p={6}
        m={3}
        borderRadius={6}
        borderWidth={1}
        borderStyle={'solid'}
        borderColor={useColorModeValue('gray.200', 'gray.700')}
        justifyContent="space-between"
        flexDirection={{ base: 'row', sm: 'row' }}
        onClick={(e) => navigate('/tema/' + idTema)}
      >
        <Box
          marginTop={{ base: '1', sm: '5' }}
          display="flex"
          flexDirection={{ base: 'column', sm: 'row' }}
          justifyContent="space-between"
        >
          <Box
            display="flex"
            flex="1"
            marginRight="3"
            position="relative"
            alignItems="center"
          ></Box>
          <Box
            display="flex"
            flex="1"
            flexDirection="column"
            justifyContent="center"
            marginTop={{ base: '3', sm: '0' }}
          >
            <Heading>{nome}</Heading>
            <Text
              as="p"
              marginTop="2"
              color={useColorModeValue('gray.700', 'gray.200')}
              fontSize="lg"
            ></Text>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Container p="6" maxW="680px">
      {temas.map((tema, key) => {
        return (
          <Stack key={key}>
            listagem de temas
            <ContentTemas item={tema} />
          </Stack>
        );
      })}
    </Container>
  );
};

export default FeedTema;
