import { Heading, Text } from "@chakra-ui/react";
import React from "react";

const DicasDeSeguranca = () => {
	return (
		<>
			<Heading as={"h3"}>Dicas de segurança</Heading>
			<Text>
				Conhecer pessoas novas é muito legal, mas você deve sempre tomar cuidado
				ao interagir com desconhecidos. Use o bom senso e priorize sua
				segurança, tanto ao trocar mensagens iniciais quanto ao se encontrar
				pessoalmente. Embora você não possa controlar as ações alheias, há
				coisas que você pode fazer para ajudá-lo a permanecer seguro em sua
				experiência com o Tinder.
			</Text>
			<br />
			<Heading as="h4">Segurança online</Heading>
			<ul>
				<li>
					<p>
						<strong>Nunca envie dinheiro ou informações financeiras</strong>
					</p>
					<p>
						Nunca envie dinheiro, especialmente por transferência, mesmo que a
						pessoa alegue estar em uma situação de emergência. A transferência
						bancária é como enviar dinheiro vivo: é praticamente impossível
						reverter a transação ou rastrear o dinheiro. Nunca compartilhe
						informações que possam ser usadas para acessar suas contas
						financeiras. Se outro usuário pedir dinheiro, informe-nos
						imediatamente.
					</p>
					<p>
						Para dicas sobre como evitar golpes, confira alguns conselhos da
						Comissão Federal de Comércio dos EUA{" "}
						<a href="https://www.consumer.ftc.gov/articles/0004-online-dating-scams">
							no site da FTC
						</a>{" "}
						.
					</p>
				</li>
				<li>
					<p>
						<strong>Proteja suas informações pessoais</strong>
					</p>
					<p>
						Nunca compartilhe informações pessoais, como números dos documentos,
						endereço residencial ou comercial, ou detalhes sobre sua rotina
						diária (por exemplo, em que dias você vai à academia) com
						desconhecidos. Se você tiver filhos, limite as informações que você
						compartilha sobre eles no seu perfil e nas primeiras comunicações.
						Evite dar detalhes, como os nomes de seus filhos, que escola
						frequentam ou suas idades e gêneros.
					</p>
				</li>
				<li>
					<p>
						<strong>Fique na plataforma</strong>
					</p>
					<p>
						Mantenha as conversas na plataforma do Tinder enquanto está
						conhecendo alguém. Usuários mal-intencionados muitas vezes tentam
						migrar as conversas imediatamente para mensagens de texto,
						aplicativos de mensagem, e-mail ou telefone.
					</p>
				</li>
				<li>
					<p>
						<strong>
							Denuncie todos os comportamentos suspeitos e ofensivos
						</strong>
					</p>
					<p>
						Você sabe quando alguém passou dos limites, e quando isso acontece,
						queremos saber. Bloqueie e denuncie quem violar nossos termos. Aqui
						estão alguns exemplos de violações:
					</p>
					<ul>
						<li>Pedidos de dinheiro ou doações</li>
						<li>Usuários menores de idade</li>
						<li>Perturbação, ameaças e mensagens ofensivas</li>
						<li>
							Comportamento impróprio ou prejudicial durante ou depois de se
							encontrar pessoalmente
						</li>
						<li>Perfis falsos</li>
						<li>
							Spam ou ofertas, incluindo links para sites comerciais ou
							tentativas de vender produtos ou serviços
						</li>
					</ul>
					<p>
						Você pode relatar comportamentos suspeitos em qualquer página de
						perfil ou janela de mensagens{" "}
						<a href="https://www.help.tinder.com/hc/requests/new">aqui</a>. Para
						mais informações, confira nossas{" "}
						<a href="/community-guidelines">Diretrizes Comunitárias</a>.
					</p>
				</li>
				<li>
					<p>
						<strong>Proteja sua conta</strong>
					</p>
					<p>
						Escolha uma senha forte e sempre tome cuidado ao fazer login na sua
						conta em um computador público ou compartilhado. O Tinder nunca
						enviará um e-mail para você solicitando seu nome de usuário e sua
						senha. Se receber um e-mail pedindo informações de conta, denuncie
						imediatamente.
					</p>
				</li>
			</ul>
			<h2>Conhecendo alguém pessoalmente</h2>
			<ul>
				<li>
					<p>
						<strong>Conte a seus amigos e parentes sobre seus planos</strong>
					</p>
					<p>
						Conte a um amigo ou parente sobre seus planos, incluindo aonde vai e
						quando será o encontro. Deixe seu celular carregado e mantenha-o
						sempre com você.
					</p>
				</li>
				<li>
					<p>
						<strong>Esteja no controle de seu transporte</strong>
					</p>
					<p>
						Queremos que você tenha o controle de como chegar e sair de seu
						encontro, para poder ir embora quando quiser. Se você estiver
						dirigindo, é recomendável ter um plano B, como um aplicativo de
						carona ou um amigo para buscar você.
					</p>
				</li>
				<li>
					<p>
						<strong>Conheça seus limites</strong>
					</p>
					<p>
						Esteja ciente dos efeitos de drogas ou álcool em você
						especificamente. Essas substâncias podem prejudicar seu bom senso e
						sua atenção. Se a outra pessoa tentar pressionar você a usar drogas
						ou beber mais do que se sente confortável, resista e termine o
						encontro.
					</p>
				</li>
				<li>
					<p>
						<strong>Não deixe bebidas ou itens pessoais sem supervisão</strong>
					</p>
					<p>
						Saiba de onde vem sua bebida e saiba sempre onde ela está. Aceite
						apenas bebidas servidas diretamente pelo garçom ou atendente. Muitas
						substâncias que são colocadas em bebidas para facilitar o assédio
						sexual são inodoras, incolores e sem sabor. Além disso, mantenha
						sempre com você celular, bolsa, carteira e qualquer objeto que
						contenha informações pessoais.
					</p>
				</li>
				<li>
					<p>
						<strong>Se você se sentir desconfortável, vá embora</strong>
					</p>
					<p>
						Não tem problema terminar o encontro antes do esperado se você
						estiver se sentindo desconfortável. Na realidade, é melhor fazer
						isso. E se sua intuição lhe disser que há algo errado, ou se você
						não se sentir em segurança, peça ajuda ao atendente ou garçom.
					</p>
				</li>
			</ul>
		</>
	);
};

export default DicasDeSeguranca;
