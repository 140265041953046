import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BsFillHouseFill } from "react-icons/bs";

import {
	Button,
	Flex,
	Heading,
	Input,
	Stack,
	Text,
	useColorModeValue,
	SimpleGrid,
	useToast,
	FormControl,
	FormLabel,
	FormHelperText,
	FormErrorMessage,
	Textarea,
	Select,
} from "@chakra-ui/react";
import { ENDERECO_OBJ_BASE } from "./compose/endereco";
import {
	ENDERECO_REGISTER_POST,
	OPTIONS_CIDADE_GET,
	OPTIONS_ESTADO_GET,
} from "../../api";

export default function AdicionarEndereco() {
	const [endereco, setEndereco] = useState(ENDERECO_OBJ_BASE);
	const [loading, setLoading] = useState(false);
	const [listOptionsEstado, setListOptionsEstado] = useState([]);
	const [listOptionsCidade, setListOptionsCidade] = useState([]);
	const toast = useToast();
	const navigate = useNavigate();

	useEffect(() => {
		loadListOptionsEstado();
	}, []);

	useEffect(() => {
		if (endereco.uf.value != "") loadListOptionsCidade();
	}, [endereco.uf.value]);

	const handleVariable = ({ target }, callback = null) => {
		const obj_aux = endereco[target.name];
		setEndereco({
			...endereco,
			[target.name]: { ...obj_aux, value: target.value, isInvalid: false },
		});
	};

	/**
	 * Verifica se existe algum atributo de endereço vazio
	 */
	const checkAllVariables = () => {
		let thereIsEmptyField = false;
		let aux_clone = structuredClone(endereco);
		for (let attribute in endereco) {
			if (!endereco[attribute].required || endereco[attribute].value != "")
				continue;
			thereIsEmptyField = true;
			console.log(aux_clone[attribute]);
			aux_clone[attribute].isInvalid = true;
		}
		setEndereco(aux_clone);
		if (thereIsEmptyField) {
			toast({
				title: `Preencha todos os campos`,
				position: "top",
				status: "error",
				isClosable: true,
			});
			setEndereco(aux_clone);
		}
		return thereIsEmptyField;
	};

	const cadastrarEndereco = async () => {
		try {
			setLoading(true);
			if (checkAllVariables()) return;

			const token = window.localStorage.getItem("token");
			const { url, options } = ENDERECO_REGISTER_POST(token, endereco);
			const response = await fetch(url, options);
			const objResponse = await response.json();
			if (objResponse > 0) {
				toast({
					title: `Endereço cadastrado com sucesso`,
					position: "top",
					status: "success",
					isClosable: true,
				});
				navigate("/");
			} else {
				toast({
					title: `Problema ao cadastrar endereço, tente mais tarde`,
					position: "top",
					status: "error",
					isClosable: true,
				});
			}
		} catch (e) {
			console.log(e);
		} finally {
			setLoading(false);
		}
	};

	const loadListOptionsEstado = async () => {
		const token = window.localStorage.getItem("token");
		const { url, options } = OPTIONS_ESTADO_GET(token);
		const response = await fetch(url, options);
		const result = await response.json();
		if (!response.ok) return;
		setListOptionsEstado(result.content);
	};

	const loadListOptionsCidade = async () => {
		const token = window.localStorage.getItem("token");
		const { url, options } = OPTIONS_CIDADE_GET(token, endereco.uf.value);
		const response = await fetch(url, options);
		const result = await response.json();
		if (!response.ok) return;
		setListOptionsCidade(result.content);
	};

	return (
		<Flex
			minH={"100vh"}
			align={"center"}
			justify={"center"}
			bg={useColorModeValue("gray.50", "gray.800")}>
			<Stack
				spacing={4}
				w={"78%"}
				maxW={"100vw"}
				bg={useColorModeValue("white", "gray.700")}
				rounded={"xl"}
				boxShadow={"lg"}
				p={6}
				my={12}>
				<Heading
					lineHeight={1.1}
					fontSize={{ base: "2xl", md: "3xl" }}>
					Cadastrar Endereço
				</Heading>
				<Text
					fontSize={{ base: "sm", sm: "md" }}
					color={useColorModeValue("gray.800", "gray.400")}></Text>

				<SimpleGrid
					columns={1}
					spacing={5}>
					<FormControl isInvalid={endereco.titulo.isInvalid}>
						<FormLabel>Título</FormLabel>
						<Input
							placeholder="Ex: Minha casa"
							name="titulo"
							required
							onChange={handleVariable}
							_placeholder={{ color: "gray.500" }}
							type="text"
							min={0}
							max={200}
						/>

						<FormErrorMessage>
							É essencial informar preencher esse campo.
						</FormErrorMessage>
					</FormControl>

					<FormControl isInvalid={endereco.cep.isInvalid}>
						<FormLabel>CEP</FormLabel>
						<Input
							placeholder="Ex: 01001000"
							name="cep"
							required
							onChange={handleVariable}
							_placeholder={{ color: "gray.500" }}
							type="number"
							min={0}
							max={200}
						/>

						<FormErrorMessage>
							É essencial informar preencher esse campo.
						</FormErrorMessage>
					</FormControl>

					<FormControl isInvalid={endereco.uf.isInvalid}>
						<FormLabel>Unidade Federativa</FormLabel>
						<Select
							placeholder="-- SELECIONE --"
							name="uf"
							required
							onChange={(event) => handleVariable(event, loadListOptionsCidade)}
							_placeholder={{ color: "gray.500" }}
							type="text"
							min={0}
							max={200}>
							{listOptionsEstado &&
								listOptionsEstado.map((item) => {
									return (
										<option
											key={item.idEstado}
											value={item.idEstado}>
											{item.nome}
										</option>
									);
								})}
						</Select>
						<FormErrorMessage>
							É essencial informar preencher esse campo.
						</FormErrorMessage>
					</FormControl>

					<FormControl isInvalid={endereco.cidade.isInvalid}>
						<FormLabel>Cidade</FormLabel>
						<Select
							placeholder="-- SELECIONE --"
							name="cidade"
							required
							onChange={handleVariable}
							_placeholder={{ color: "gray.500" }}
							type="text"
							min={0}
							max={200}>
							{listOptionsCidade &&
								listOptionsCidade.map((item) => {
									return (
										<option
											key={item.idCidade}
											value={item.idCidade}>
											{item.nome}
										</option>
									);
								})}
						</Select>
						<FormErrorMessage>
							É essencial informar preencher esse campo.
						</FormErrorMessage>
					</FormControl>

					<FormControl isInvalid={endereco.bairro.isInvalid}>
						<FormLabel>Bairro</FormLabel>
						<Input
							placeholder="Bairro"
							name="bairro"
							required
							onChange={handleVariable}
							_placeholder={{ color: "gray.500" }}
							type="text"
							min={0}
							max={200}
						/>
						<FormErrorMessage>
							É essencial informar preencher esse campo.
						</FormErrorMessage>
					</FormControl>

					<FormControl isInvalid={endereco.rua.isInvalid}>
						<FormLabel>Rua</FormLabel>
						<Input
							placeholder="Rua"
							name="rua"
							required
							onChange={handleVariable}
							_placeholder={{ color: "gray.500" }}
							type="text"
						/>
						<FormErrorMessage>
							É essencial informar preencher esse campo.
						</FormErrorMessage>
					</FormControl>

					<FormControl isInvalid={endereco.numero.isInvalid}>
						<FormLabel>Número</FormLabel>
						<Input
							placeholder="Ex: 123"
							name="numero"
							required
							onChange={handleVariable}
							_placeholder={{ color: "gray.500" }}
							type="number"
							min={0}
							max={200}
						/>
						<FormErrorMessage>
							É essencial informar preencher esse campo.
						</FormErrorMessage>
					</FormControl>

					<FormControl>
						<FormLabel>
							Complemento <small>(Opcional)</small>
						</FormLabel>
						<Input
							placeholder="Complemento"
							name="complemento"
							onChange={handleVariable}
							_placeholder={{ color: "gray.500" }}
							type="text"
							min={0}
							max={200}
						/>
						<FormErrorMessage>
							É essencial informar preencher esse campo.
						</FormErrorMessage>
					</FormControl>

					<FormControl isInvalid={endereco.referencia.isInvalid}>
						<FormLabel>Referência</FormLabel>
						<Input
							placeholder="Ex: Ao lado do mercado"
							name="referencia"
							required
							onChange={handleVariable}
							_placeholder={{ color: "gray.500" }}
							type="text"
						/>
						<FormErrorMessage>
							É essencial informar preencher esse campo.
						</FormErrorMessage>
					</FormControl>
				</SimpleGrid>

				<Stack spacing={6}>
					<Button
						bg={"blue.400"}
						color={"white"}
						_hover={{
							bg: "blue.500",
						}}
						onClick={cadastrarEndereco}>
						Cadastrar Endereco
					</Button>
				</Stack>
			</Stack>
		</Flex>
	);
}
