export const SOCIAL_OBJ_BASE = {
  titulo: {
    value: '',
    isInvalid: false,
  },
  tema: {
    value: '',
    isInvalid: false,
  },
  endereco: {
    value: '',
    isInvalid: false,
  },
  descricao: {
    value: '',
    isInvalid: false,
  },
  limiteConvidados: {
    value: '',
    isInvalid: false,
  },
  contribuicao: {
    value: '',
    isInvalid: false,
  },
  dataInicio: {
    value: '',
    isInvalid: false,
  },
};
