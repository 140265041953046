import React, { useEffect, useState } from "react";
import dateTimeBR from "../../../components/Helper/DateTimeBR";

import {
	Box,
	chakra,
	Container,
	Stack,
	Text,
	Image,
	Flex,
	VStack,
	Button,
	Heading,
	SimpleGrid,
	StackDivider,
	useColorModeValue,
	List,
	ListItem,
	Spinner,
	useToast,
	Avatar,
	Badge,
	TableContainer,
	Table,
	TableCaption,
	Thead,
	Tr,
	Th,
	Tbody,
	Tfoot,
	Td,
	Grid,
	GridItem,
	Tabs,
	TabList,
	Tab,
	TabPanels,
	TabPanel,
	Fade,
	Skeleton,
} from "@chakra-ui/react";
import { IoMdCar } from "react-icons/io";
import { SOCIAL_OBJ_BASE } from "../../AdicionarSocial/compose/social";
import { ButtonSolicitacao } from "./ButtonSolicitacao";
import { DETAILS_SOCIAL_GET } from "../../../api";

export function DadosDaSocial({ setImgBanner }) {
	const [social, setSocial] = useState(SOCIAL_OBJ_BASE);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const loadSocial = async () => {
			try {
				setLoading(true);
				const token = window.localStorage.getItem("token");
				const id_social = window.location.href.split("/").pop();
				const { url, options } = DETAILS_SOCIAL_GET(token, id_social);
				const response = await fetch(url, options);
				const social = await response.json();
				setSocial(social);
				console.log(social.imgBanner);
				setImgBanner(social.imgBanner);
			} catch (e) {
			} finally {
				setLoading(false);
			}
		};
		loadSocial();
	}, []);

	if (loading)
		return (
			<Skeleton
				height="20px"
				width={"100%"}
			/>
		);

	return (
		<Stack
			spacing={{
				base: 6,
				md: 10,
			}}>
			<Box as={"header"}>
				<Heading
					lineHeight={1.1}
					fontWeight={600}
					fontSize={{
						base: "2xl",
						sm: "4xl",
						lg: "5xl",
					}}>
					{social.titulo}{" "}
					<Text
						fontSize={"sm"}
						color={useColorModeValue("yellow.500", "yellow.300")}>
						Nota Mínima: 4.2 ★★★★
					</Text>
				</Heading>
				<Text
					color={useColorModeValue("gray.900", "gray.400")}
					fontWeight={300}
					fontSize={"2xl"}>
					{dateTimeBR(social.dataInicio)}
				</Text>
			</Box>

			<Stack
				spacing={{
					base: 4,
					sm: 6,
				}}
				direction={"column"}
				divider={
					<StackDivider
						borderColor={useColorModeValue("gray.200", "gray.600")}
					/>
				}>
				<Box>
					<Text
						fontSize={{
							base: "16px",
							lg: "18px",
						}}
						color={useColorModeValue("yellow.500", "yellow.300")}
						fontWeight={"500"}
						textTransform={"uppercase"}
						mb={"4"}>
						Descrição
					</Text>

					<Text
						color={useColorModeValue("gray.900", "gray.400")}
						fontWeight={300}
						fontSize={"2xl"}>
						{social.descricao}
					</Text>
				</Box>
				<Box>
					<Text
						fontSize={{
							base: "16px",
							lg: "18px",
						}}
						color={useColorModeValue("yellow.500", "yellow.300")}
						fontWeight={"500"}
						textTransform={"uppercase"}
						mb={"4"}>
						Informações do Rolê
					</Text>

					<SimpleGrid
						columns={{
							base: 1,
							md: 2,
						}}
						spacing={10}>
						<List spacing={2}>
							<Text>
								<b>Tema </b>
								{social.tema}{" "}
							</Text>
							<Text>
								<b>Endereço </b>
								{social.endereco}{" "}
							</Text>
							<Text>
								<b>Limite de convidados </b> {social.limiteConvidados}{" "}
							</Text>
							<Text>
								<b>Contribuição</b> {social.contribuicao}{" "}
							</Text>
						</List>

						<List spacing={3}>
							<Text>
								<b>Cidade</b> {social.cidade}
							</Text>

							<Text>
								<b>Bairro</b> {social.bairro}
							</Text>

							<Text>
								<b>Rua</b> {social.rua}
							</Text>

							<Text>
								<b>Número</b> {social.numero}
							</Text>

							{social.complemento && (
								<Text>
									<b>Complemento</b> {social.complemento}
								</Text>
							)}

							{social.referencia && (
								<Text>
									<b>Referência </b>
									{social.referencia}
								</Text>
							)}

							{social.numero && (
								<Text>
									<b>Número </b>
									{social.numero}
								</Text>
							)}
						</List>
					</SimpleGrid>
				</Box>
			</Stack>
			<ButtonSolicitacao />
			<Stack
				direction="row"
				alignItems="center"
				justifyContent={"center"}></Stack>
		</Stack>
	);
}
