import React, { useEffect, useState } from "react";
import InputMask from "react-input-mask";

import {
	Box,
	Flex,
	Stack,
	Heading,
	Text,
	Container,
	Input,
	Button,
	SimpleGrid,
	Avatar,
	AvatarGroup,
	useBreakpointValue,
	IconProps,
	Icon,
	FormErrorMessage,
	FormControl,
	Wrap,
	WrapItem,
	useToast,
	FormLabel,
	Spinner,
} from "@chakra-ui/react";
import { userbase } from "./compose/userbase";
import { USER_REGISTER_POST } from "../../api";
import { Navigate, useNavigate } from "react-router-dom";
import Typewriter from "typewriter-effect";

const avatars = [
	{
		name: "Ryan Florence",
		url: "https://bit.ly/ryan-florence",
	},
	{
		name: "Segun Adebayo",
		url: "https://bit.ly/sage-adebayo",
	},
	{
		name: "Kent Dodds",
		url: "https://bit.ly/kent-c-dodds",
	},
	{
		name: "Prosper Otemuyiwa",
		url: "https://bit.ly/prosper-baba",
	},
	{
		name: "Christian Nwamba",
		url: "https://bit.ly/code-beast",
	},
];

const titles = ["Ta afim de sair?", "Conhecer a galera?", "Curtir a noite?"];

export default function Register() {
	const [user, setUser] = useState(userbase);
	const [lockSubmit, setLockSubmit] = useState(false);
	const [loadingSubmit, setLoadingSubmit] = useState(false);
	const toast = useToast();
	const navigate = useNavigate();

	const handleVariable = ({ target }) => {
		setUser({
			...user,
			[target.name]: {
				value: target.value,
				isInvalid: !Boolean(target.value),
			},
		});
	};

	const confirmarSenha = ({ target }) => {
		if (target.value != user.password.value) setLockSubmit(true);
		else setLockSubmit(false);
		if (target.value == "") setLockSubmit(false);
	};

	const validateFields = () => {
		const userProperties = Object.getOwnPropertyNames(user);
		let existeCampoVazio = false;
		let aux_clone = structuredClone(user);
		userProperties.map((propertie) => {
			if (aux_clone[propertie].value === "") {
				existeCampoVazio = true;
				aux_clone[propertie].isInvalid = true;
			}
		});
		setUser(aux_clone);

		if (existeCampoVazio) {
			toast({
				title: `Preencha todos os campos`,
				position: "top",
				status: "error",
				isClosable: true,
			});
			return false;
		}

		return true;
	};

	const submit = (e) => {
		e.preventDefault();
		if (!validateFields()) return;
		createUser();
	};

	const createUser = async () => {
		try {
			setLoadingSubmit(true);
			const { url, options } = USER_REGISTER_POST(user);
			const response = await fetch(url, options);
			const result = await response.json();
			if (response.ok) {
				toast({
					title: `Confirmação de cadastro enviada por email`,
					position: "top",
					status: "success",
					isClosable: true,
				});
				navigate("/login");
			} else {
				toast({
					title: result.message,
					position: "top",
					status: "error",
					isClosable: true,
				});
			}
		} catch (e) {
			toast({
				title: `Houve algum problema, por favor tente mais tarde!`,
				position: "top",
				status: "error",
				isClosable: true,
			});
		} finally {
			setLoadingSubmit(false);
		}
	};

	return (
		<>
			<Box position={"relative"}>
				<Container
					as={SimpleGrid}
					maxW={"7xl"}
					columns={{ base: 1, md: 2 }}
					spacing={{ base: 10, lg: 32 }}
					py={{ base: 10, sm: 20, lg: 32 }}>
					<Stack spacing={{ base: 10, md: 20 }}>
						<Heading
							lineHeight={1.1}
							fontSize={{
								base: "3xl",
								sm: "4xl",
								md: "5xl",
								lg: "6xl",
							}}>
							<Typewriter
								options={{
									strings: titles,
									autoStart: true,
									loop: true,
									deleteSpeed: 6,
								}}
							/>
							<Text
								as={"span"}
								bgGradient="linear(to-r, red.400,pink.400)"
								bgClip="text">
								Bora dar um Rolê!
							</Text>
						</Heading>
						<Stack
							direction={"row"}
							spacing={4}
							align={"center"}>
							<AvatarGroup>
								{avatars.map((avatar, key) => (
									<Avatar
										key={key}
										name={avatar.name}
										src={avatar.url}
										size={useBreakpointValue({
											base: "md",
											md: "lg",
										})}
										position={"relative"}
										zIndex={2}
										_before={{
											content: '""',
											width: "full",
											height: "full",
											rounded: "full",
											transform: "scale(1.125)",
											bgGradient:
												"linear(to-bl, red.400,pink.400)",
											position: "absolute",
											zIndex: -1,
											top: 0,
											left: 0,
										}}
									/>
								))}
							</AvatarGroup>
							<Text
								fontFamily={"heading"}
								fontSize={{ base: "4xl", md: "6xl" }}>
								+
							</Text>
							<Flex
								align={"center"}
								justify={"center"}
								fontFamily={"heading"}
								fontSize={{ base: "sm", md: "lg" }}
								bg={"gray.800"}
								color={"white"}
								rounded={"full"}
								width={useBreakpointValue({
									base: "44px",
									md: "60px",
								})}
								height={useBreakpointValue({
									base: "44px",
									md: "60px",
								})}
								position={"relative"}
								_before={{
									content: '""',
									width: "full",
									height: "full",
									rounded: "full",
									transform: "scale(1.125)",
									bgGradient:
										"linear(to-bl, orange.400,yellow.400)",
									position: "absolute",
									zIndex: -1,
									top: 0,
									left: 0,
								}}>
								YOU
							</Flex>
						</Stack>
					</Stack>
					<FormControl>
						<Stack
							bg={"gray.50"}
							rounded={"xl"}
							p={{ base: 4, sm: 6, md: 8 }}
							spacing={{ base: 8 }}
							maxW={{ lg: "lg" }}>
							<Stack spacing={4}>
								<Heading
									color={"gray.800"}
									lineHeight={1.1}
									fontSize={{
										base: "2xl",
										sm: "3xl",
										md: "4xl",
									}}>
									Cadastre-se
									<Text
										as={"span"}
										bgGradient="linear(to-r, red.400,pink.400)"
										bgClip="text">
										!
									</Text>
								</Heading>
							</Stack>

							<Box
								as={"form"}
								mt={10}>
								<Stack spacing={4}>
									<FormControl
										isInvalid={user.nome.isInvalid}>
										<Input
											placeholder="Nome"
											name="nome"
											onChange={handleVariable}
											bg={"gray.100"}
											border={0}
											color={"gray.500"}
											_placeholder={{
												color: "gray.500",
											}}
										/>
									</FormControl>
									<FormControl
										isInvalid={user.sobrenome.isInvalid}>
										<Input
											placeholder="Sobrenome"
											name="sobrenome"
											onChange={handleVariable}
											bg={"gray.100"}
											border={0}
											color={"gray.500"}
											_placeholder={{
												color: "gray.500",
											}}
										/>
									</FormControl>
									<FormControl
										isInvalid={user.email.isInvalid}>
										<Input
											placeholder="Email"
											name="email"
											onChange={handleVariable}
											bg={"gray.100"}
											border={0}
											color={"gray.500"}
											_placeholder={{
												color: "gray.500",
											}}
										/>
									</FormControl>
									<FormControl
										isInvalid={user.phone.isInvalid}>
										<Input
											as={InputMask}
											placeholder="( ) _____-____"
											mask="(99) 99999-9999"
											name="phone"
											onChange={handleVariable}
											bg={"gray.100"}
											border={0}
											color={"gray.500"}
											_placeholder={{
												color: "gray.500",
											}}
										/>
									</FormControl>
									<FormControl
										isInvalid={user.password.isInvalid}>
										<Input
											placeholder="Senha"
											name="password"
											type="password"
											onChange={handleVariable}
											bg={"gray.100"}
											border={0}
											color={"gray.500"}
											_placeholder={{
												color: "gray.500",
											}}
										/>
									</FormControl>
									<FormControl
										isInvalid={
											user.confirmacaosenha.isInvalid ||
											lockSubmit
										}>
										<Input
											placeholder="Confirmação de Senha"
											name="confirmacaosenha"
											type="password"
											onChange={(e) => {
												handleVariable(e);
												confirmarSenha(e);
											}}
											bg={"gray.100"}
											border={0}
											color={"gray.500"}
											_placeholder={{
												color: "gray.500",
											}}
										/>
									</FormControl>
								</Stack>
								<Button
									type="submit"
									fontFamily={"heading"}
									mt={8}
									w={"full"}
									bgGradient="linear(to-r, red.400,pink.400)"
									color={"white"}
									_hover={{
										bgGradient:
											"linear(to-r, red.400,pink.400)",
										boxShadow: "xl",
									}}
									onClick={submit}
									disabled={lockSubmit}
									isLoading={loadingSubmit}>
									Enviar
								</Button>
							</Box>
						</Stack>
					</FormControl>
				</Container>
				<Blur
					position={"absolute"}
					top={-10}
					left={-10}
					style={{ filter: "blur(70px)" }}
				/>
			</Box>
		</>
	);
}

export const Blur = (props) => {
	return (
		<Icon
			width={useBreakpointValue({ base: "100%", md: "40vw", lg: "30vw" })}
			zIndex={useBreakpointValue({ base: -1, md: -1, lg: 0 })}
			height="560px"
			viewBox="0 0 528 560"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<circle
				cx="71"
				cy="61"
				r="111"
				fill="#F56565"
			/>
			<circle
				cx="244"
				cy="106"
				r="139"
				fill="#ED64A6"
			/>
			<circle
				cy="291"
				r="139"
				fill="#ED64A6"
			/>
			<circle
				cx="80.5"
				cy="189.5"
				r="101.5"
				fill="#ED8936"
			/>
			<circle
				cx="196.5"
				cy="317.5"
				r="101.5"
				fill="#ECC94B"
			/>
			<circle
				cx="70.5"
				cy="458.5"
				r="101.5"
				fill="#48BB78"
			/>
			<circle
				cx="426.5"
				cy="-0.5"
				r="101.5"
				fill="#4299E1"
			/>
		</Icon>
	);
};
