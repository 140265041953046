import React from 'react';

const NotFound = () => {
  return (
    <>
      <div id="main">
        <div className="fof">
          <h1>404 - Ops! Página não econtrada</h1>
        </div>
      </div>
    </>
  );
};

export default NotFound;
