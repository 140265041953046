export const ENDERECO_OBJ_BASE = {
	titulo: {
		value: "",
		isInvalid: false,
		required: true,
	},
	bairro: {
		value: "",
		isInvalid: false,
		required: true,
	},
	cep: {
		value: "",
		isInvalid: false,
		required: true,
	},
	uf: {
		value: "",
		isInvalid: false,
		required: true,
	},
	cidade: {
		value: "",
		isInvalid: false,
		required: true,
	},
	rua: {
		value: "",
		isInvalid: false,
		required: true,
	},
	numero: {
		value: "",
		isInvalid: false,
		required: true,
	},
	complemento: {
		value: "",
		isInvalid: false,
		required: false,
	},
	referencia: {
		value: "",
		isInvalid: false,
		required: true,
	},
};
