import {
	Avatar,
	AvatarGroup,
	Flex,
	Heading,
	Stack,
	Text,
	useBreakpointValue,
} from "@chakra-ui/react";
import React from "react";
import Typewriter from "typewriter-effect";

const titles = ["Ta afim de sair?", "Conhecer a galera?", "Curtir a noite?"];

const avatars = [
	{
		name: "Ryan Florence",
		url: "https://xsgames.co/randomusers/avatar.php?g=male",
	},
	{
		name: "Prosper Otemuyiwa",
		url: "https://xsgames.co/randomusers/avatar.php?g=pixel",
	},
	{
		name: "Kent Dodds",
		url: "https://xsgames.co/randomusers/avatar.php?g=female",
	},
	{
		name: "Christian Nwamba",
		url: "https://joeschmoe.io/api/v1/some-id",
	},
];

export function VisibleEffect({ key }) {
	return (
		<Stack
			spacing={{
				base: 10,
				md: 20,
			}}>
			<Heading
				lineHeight={1.1}
				fontSize={{
					base: "3xl",
					sm: "4xl",
					md: "5xl",
					lg: "6xl",
				}}>
				<Typewriter
					options={{
						strings: titles,
						autoStart: true,
						loop: true,
						deleteSpeed: 6,
					}}
				/>
				<Text
					as={"span"}
					bgGradient="linear(to-r, red.400,pink.400)"
					bgClip="text">
					Então
				</Text>{" "}
				<br />
				Bora pro Rolê!
			</Heading>
			<Stack
				direction={"row"}
				spacing={4}
				align={"center"}>
				<AvatarGroup>
					{avatars.map((avatar, key) => (
						<Avatar
							key={key}
							name={avatar.name}
							src={avatar.url}
							size={useBreakpointValue({
								base: "md",
								md: "lg",
							})}
							position={"relative"}
							zIndex={2}
							_before={{
								content: '""',
								width: "full",
								height: "full",
								rounded: "full",
								transform: "scale(1.125)",
								bgGradient: "linear(to-bl, red.400,pink.400)",
								position: "absolute",
								zIndex: -1,
								top: 0,
								left: 0,
							}}
						/>
					))}
				</AvatarGroup>
				<Text
					fontFamily={"heading"}
					fontSize={{
						base: "4xl",
						md: "6xl",
					}}>
					+
				</Text>
				<Flex
					align={"center"}
					justify={"center"}
					fontFamily={"heading"}
					fontSize={{
						base: "sm",
						md: "lg",
					}}
					bg={"gray.800"}
					color={"white"}
					rounded={"full"}
					width={useBreakpointValue({
						base: "44px",
						md: "60px",
					})}
					height={useBreakpointValue({
						base: "44px",
						md: "60px",
					})}
					position={"relative"}
					_before={{
						content: '""',
						width: "full",
						height: "full",
						rounded: "full",
						transform: "scale(1.125)",
						bgGradient: "linear(to-bl, orange.400,yellow.400)",
						position: "absolute",
						zIndex: -1,
						top: 0,
						left: 0,
					}}>
					VOCÊ
				</Flex>
			</Stack>
		</Stack>
	);
}
