import * as React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import { UserStorage } from "./UserContext";
import AdicionarSocial from "./view/AdicionarSocial";
import AdicionarTema from "./view/Tema";
import FeedTema from "./view/FeedTema";
import Register from "./view/Register";
import AdicionarEndereco from "./view/AdicionarEndereco";
import ForgotPassword from "./view/ForgotPassword";
import Login from "./view/Login";
import NotFound from "./view/NotFound";
import ProtectedRoute from "./components/Helper/ProtectedRoute";
import Feed from "./view/Feed";
import Social from "./view/Social";
import "./index.css";
import ConfirmacaoUsuario from "./view/ConfirmacaoUsuario";

const pretectedRouteMin = (element) => {
	return <ProtectedRoute>{element}</ProtectedRoute>;
};

function App() {
	return (
		<BrowserRouter>
			<UserStorage>
				<Header />
				<Routes>
					<Route
						path="/"
						element={pretectedRouteMin(<Feed />)}
					/>
					<Route
						path="/login"
						element={<Login />}
					/>
					<Route
						path="/confirmacao-usuario"
						element={<ConfirmacaoUsuario />}
					/>
					<Route
						path="/cadastro"
						element={<Register />}
					/>
					<Route
						path="/reseteSenha"
						element={<ForgotPassword />}
					/>
					<Route
						path="/social/*"
						element={pretectedRouteMin(<Social />)}
					/>
					<Route
						path="/adicionar-social"
						element={pretectedRouteMin(<AdicionarSocial />)}
					/>
					<Route
						path="/tema/*"
						element={pretectedRouteMin(<Social />)}
					/>
					<Route
						path="/adicionar-tema"
						element={pretectedRouteMin(<AdicionarTema />)}
					/>
					<Route
						path="/adicionar-endereco"
						element={pretectedRouteMin(<AdicionarEndereco />)}
					/>
					<Route
						path="/listar-temas"
						element={pretectedRouteMin(<FeedTema />)}
					/>
					<Route
						path="*"
						element={<NotFound />}
					/>
				</Routes>
				<Footer />
			</UserStorage>
		</BrowserRouter>
	);
}

export default App;
