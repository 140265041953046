import { StarIcon } from "@chakra-ui/icons";
import {
	Avatar,
	Badge,
	Box,
	Button,
	ButtonGroup,
	Flex,
	list,
	Popover,
	PopoverArrow,
	PopoverBody,
	PopoverCloseButton,
	PopoverContent,
	PopoverFooter,
	PopoverHeader,
	PopoverTrigger,
	Table,
	TableContainer,
	Tbody,
	Td,
	Text,
	Tfoot,
	Th,
	Thead,
	Toast,
	Tr,
	useDisclosure,
	useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	LIST_PARTICIPANTES_SOCIAL_GET,
	PARTICIPANTE_AVALIACAO_SOCIAL_PUT,
	PARTICIPANTE_SOCIAL_PUT,
} from "../../../api";

export default function TableParticipantes() {
	const [listParticipantes, setListParticipantes] = useState([]);
	const toast = useToast();
	const navigate = useNavigate();

	useEffect(() => {
		loadListParticipantes();
	}, []);

	const loadListParticipantes = async () => {
		const token = window.localStorage.getItem("token");
		const idSocial = window.location.href.split("/").pop();
		const { url, options } = LIST_PARTICIPANTES_SOCIAL_GET(token, idSocial);
		const response = await fetch(url, options);
		const result = await response.json();
		console.log(result);
		setListParticipantes(result);
	};

	const aprovar = async (idParticipante) => {
		const token = window.localStorage.getItem("token");
		const { url, options } = PARTICIPANTE_SOCIAL_PUT(
			token,
			idParticipante,
			"APROVADO"
		);
		const response = await fetch(url, options);
		const r = await response.json();
	};

	const rejeitar = async (idParticipante) => {
		const token = window.localStorage.getItem("token");
		const { url, options } = PARTICIPANTE_SOCIAL_PUT(
			token,
			idParticipante,
			"RECUSADO"
		);
		const response = await fetch(url, options);
		const r = await response.json();
	};

	const ratingParticipante = async (id_participante, nota) => {
		const token = window.localStorage.getItem("token");
		const { url, options } = PARTICIPANTE_AVALIACAO_SOCIAL_PUT(
			token,
			id_participante,
			nota
		);
		const response = await fetch(url, options);

		if (response.ok) {
			toast({
				title: `Avaliação realizada!`,
				position: "top",
				status: "success",
				isClosable: true,
			});
			navigate();
		}
	};

	const StarsRating = ({ idParticipante, notaMedia }) => {
		return [...new Array(5)].map((arr, index) => {
			return (
				<StarIcon
					key={index}
					color={index < notaMedia ? "yellow" : ""}
					onClick={() => ratingParticipante(idParticipante, index + 1)}
				/>
			);
		});
	};

	const ParticipantesList = ({ participante }) => {
		const {
			idParticipante,
			nomeParticipante,
			status,
			notaMedia,
			categoriaUsuario,
		} = participante;

		return (
			<Tr>
				<Td>
					<Flex>
						<Avatar name={nomeParticipante} />
						<Box ml="3">
							<Text fontWeight="bold">
								{nomeParticipante}
								{categoriaUsuario.descricao == "Organizador" && (
									<Badge
										ml="1"
										colorScheme="green">
										Organizador
									</Badge>
								)}
							</Text>
							<Text fontSize="sm">
								<StarsRating
									idParticipante={idParticipante}
									notaMedia={notaMedia}
								/>
							</Text>
						</Box>
					</Flex>
				</Td>
				<Td>
					{categoriaUsuario.descricao != "Organizador" && (
						<Popover>
							<PopoverTrigger>
								<Button>{status.status}</Button>
							</PopoverTrigger>
							<PopoverContent>
								<PopoverArrow />
								<PopoverCloseButton />
								<PopoverHeader>Confirmação!</PopoverHeader>
								<PopoverBody>Aprovar participante na social?</PopoverBody>
								<PopoverFooter
									display="flex"
									justifyContent="flex-end">
									<ButtonGroup size="sm">
										<Button
											colorScheme="red"
											variant="outline"
											onClick={() => rejeitar(idParticipante)}>
											Rejeitar
										</Button>
										<Button
											colorScheme="green"
											variant="outline"
											onClick={() => aprovar(idParticipante)}>
											Aprovar
										</Button>
									</ButtonGroup>
								</PopoverFooter>
							</PopoverContent>
						</Popover>
					)}
				</Td>
				<Td isNumeric>{categoriaUsuario.descricao != "Organizador" && ""}</Td>
			</Tr>
		);
	};

	return (
		<TableContainer>
			<Table
				variant="striped"
				colorScheme="blackAlpha">
				<Thead>
					<Tr>
						<Th>Participante</Th>
						<Th>Status</Th>
						<Th isNumeric>Método de Contribuição</Th>
					</Tr>
				</Thead>
				<Tbody>
					{listParticipantes.map((participante, key) => {
						return (
							<ParticipantesList
								participante={participante}
								key={key}
							/>
						);
					})}
				</Tbody>
				<Tfoot>
					<Tr>
						<Th>Participante</Th>
						<Th>Status</Th>
						<Th isNumeric>Método de Contribuição</Th>
					</Tr>
				</Tfoot>
			</Table>
		</TableContainer>
	);
}
