import { PhoneIcon, SearchIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import React from 'react';
import { SOCIAIS_SEARCH_GET } from '../../../api';

const Search = ({ setSociais }) => {
  const searchSociais = async ({ target }) => {
    try {
      const token = window.localStorage.getItem('token');
      const { url, options } = SOCIAIS_SEARCH_GET(token, target.value);
      const response = await fetch(url, options);
      const { content } = await response.json();
      setSociais(content);
    } catch (e) {
    } finally {
    }
  };

  return (
    <Box maxW="680px" p={3}>
      <Flex>
        <InputGroup>
          <InputLeftElement
            pointerEvents="none"
            children={<SearchIcon color="gray.300" />}
          />
          <Input
            onChange={searchSociais}
            placeholder="Procurar Rolê"
            _placeholder={{ color: 'gray' }}
            backgroundColor={'whiteAlpha.200'}
            color={'dimgray'}
          />
        </InputGroup>
      </Flex>
    </Box>
  );
};

export default Search;
