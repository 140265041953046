import { useEffect, useState } from "react";
import { DETAILS_SOCIAL_GET, PARTICIPANTE_POST } from "../../api";
import { SOCIAL_OBJ_BASE } from "../AdicionarSocial/compose/social";
import {
	Container,
	Image,
	Flex,
	Grid,
	GridItem,
	Tabs,
	TabList,
	Tab,
	TabPanels,
	TabPanel,
	Fade,
} from "@chakra-ui/react";
import { IoMdCar } from "react-icons/io";
import TableParticipantes from "./compose/TableParticipantes";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { DadosDaSocial } from "./compose/DadosDaSocial";
import DicasDeSeguranca from "./compose/DicasDeSeguranca";

const Social = () => {
	ChartJS.register(ArcElement, Tooltip, Legend);

	const [imgBanner, setImgBanner] = useState();

	const data = {
		labels: ["Homem", "Mulher"],
		datasets: [
			{
				data: [2, 0],
				backgroundColor: ["blue", "pink"],
			},
		],
		borderWidth: 1,
	};

	return (
		<Container
			maxW={"7xl"}
			padding={"20px"}>
			<Grid
				templateColumns={"repeat(2, 1fr)"}
				gap={9}>
				<GridItem w={"100%"}>
					<Flex>
						<Image
							rounded={"md"}
							alt={"product image"}
							src={imgBanner}
							fit={"cover"}
							align={"center"}
							w={"100%"}
							h={{ base: "100%", sm: "400px", lg: "500px" }}
						/>
					</Flex>
				</GridItem>
				<GridItem w={"100%"}>
					<DadosDaSocial setImgBanner={setImgBanner} />
				</GridItem>
				<GridItem
					w={"100%"}
					colSpan={2}>
					<Tabs isLazy>
						<TabList>
							<Tab>Dicas de Segurança</Tab>
							<Tab>Participates</Tab>
						</TabList>

						<TabPanels>
							<TabPanel>
								{/* <Pie data={data} /> */}
								<DicasDeSeguranca />
							</TabPanel>
							<TabPanel>
								<Fade in={true}>
									<TableParticipantes />
								</Fade>
							</TabPanel>
						</TabPanels>
					</Tabs>
				</GridItem>
			</Grid>
		</Container>
	);
};

export default Social;
